import React, { useEffect, useMemo, useState } from 'react';
import { useRoutes } from 'react-router-dom';
import loadable from '@loadable/component';
import { useParams } from 'ap-utils';
import VehiclesPrices from './auctions/vehiclesPrices';

const Backoffice = ({ resources }) => {
  const prefix = 'backoffice';
  const { pathname } = useParams();
  const id = useMemo(() => pathname.split('/')[3])
  console.log(id)
  const jResources = JSON.stringify(resources || []);
  const routes = useMemo(() => {
    return JSON.parse(jResources).flatMap(r => {
      const res = r.toLowerCase();
      const Index = loadable(() => import(`../${prefix}/${res}/index`));
      const New = loadable(() => import(`../${prefix}/${res}/new`));
      const Edit = loadable(() => import(`../${prefix}/${res}/edit`));
      const Show = loadable(() => import(`../${prefix}/${res}/show`));

      return [
        { path: res, element: <Index /> },
        { path: `${res}/new`, element: <New /> },
        { path: `${res}/:id/edit`, element: <Edit id={id} /> },
        { path: `${res}/:id`, element: <Show /> },
        ...(res === 'auctions' ? [{ path: `${res}/:id/vehicles_prices`, element: <VehiclesPrices id={id} /> }] : [])
      ]
    });
  }, [jResources, prefix, id]);

  const resourceRoutes = useRoutes(routes);

  return resourceRoutes;
}

export default Backoffice;