import React from 'react';
import { connecturl } from '../../variables';
import l from '../../localization';

const ConnectLinks = ({ linkStyles = '', separator }) => {
  return <>
    <a href={`${connecturl}/login`} className={linkStyles}>{l.login}</a>
    {separator && <><br/>или<br/></>}
    <a href={`${connecturl}/signup`} className={linkStyles}>{l.register}</a>
  </>;
};

export default ConnectLinks;
